// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { GET_BOOKING_BY_ID } from "src/Redux/actions/bookings";
import { useLocation } from "react-router-dom";
import SuccessAlertComponent from "./Components/successAlert";
import FailedAlertComponent from "./Components/failedAlert";
// import PendingAlertComponent from "./Components/pendingAlert";
import BookingInfo from "../BookingInfo";

export default function CheckoutLayout() {
  // const bookingInfo = useSelector(
  //   (state) => state?.bookingReducer?.bookingInfo
  // );
  // const dispatch = useDispatch();
  const search = useLocation().search;
  const bookingId = new URLSearchParams(search).get("param1");
  const status = new URLSearchParams(search).get("status");

  // useEffect(() => {
  //   dispatch(GET_BOOKING_BY_ID(bookingId));
  // }, [bookingId, dispatch]);

  return (
    <div className="bg-gray-50">
      <div>
        {status === "S" && <SuccessAlertComponent />}
        {/* {status === "PENDING" && <PendingAlertComponent />} */}
        {status === "F" && <FailedAlertComponent />}
      </div>
      <BookingInfo paramId={bookingId} />
    </div>
  );
}
