import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GET_VOUCHERS_BRAND_BY_ID } from "src/Redux/actions/voucherBrands";
import { GET_VOUCHERS_BY_ID } from "src/Redux/actions/vouchers";
import { BOOK_VOUCHER } from "src/Redux/actions/bookings";
import ScreenLoaderComponent from "src/Components/ScreenLoader";

const inputsBasedOnTypes = {
  "E-RELOAD": [
    {
      label: "Phone Number",
      name: "phone_number",
      id: "phone_number",
      type: "number",
      maxLength: 10,
      minLength: 10,
      required: true,
      errors: {
        required: "Phone Number is required",
        minLength: "Phone Number must be minimum 10 digits",
        maxLength: "Phone Number must be maximum 10 digits",
      },
    },
  ],

  // "PIN-RELOAD": [
  //   {
  //     label: "Phone Number",
  //     name: "phone_number",
  //     id: "phone_number",
  //     type: "number",
  //     maxLength: 10,
  //     minLength: 10,
  //     required: true,
  //     errors: {
  //       required: "Phone Number is required",
  //       minLength: "Phone Number must be minimum 10 digits",
  //       maxLength: "Phone Number must be maximum 10 digits",
  //     },
  //   },
  // ],

  "GAMES-TOP-UP": [
    {
      label: "Game Id",
      name: "clientid",
      id: "clientid",
      type: "text",
      required: true,
      errors: {
        required: "Game id is required",
      },
    },
    {
      label: "Game Username",
      name: "clientusername",
      id: "clientusername",
      type: "text",
    },
    {
      label: "Game Server Id",
      name: "clientserverid",
      id: "clientserverid",
      type: "text",
      errors: {
        required: "Game Server Id is required",
      },
    },
    {
      label: "Client Sub Id",
      name: "clientsubid",
      id: "clientsubid",
      type: "text",
    },
  ],
};

// const paymentOptions = [
//   { value: "BAYD", label: "Bayad Center" },
//   { value: "BOD", label: "BDO Online Banking" },
//   { value: "CC", label: "Credit Cards" },
//   { value: "CEBL", label: "Cebuana Lhuillier" },
//   { value: "DPAY", label: "Dragonpay Prepaid Credits" },
//   { value: "ECPY", label: "ECPay" },
//   { value: "GCSH", label: "Globe Gcash" },
//   { value: "LBC", label: "LBC" },
//   { value: "PYPL", label: "PayPal" },
//   { value: "MLH", label: "M. Lhuillier" },
//   { value: "RDS", label: "Robinsons Dept Store" },
//   { value: "SMR", label: "SM Payment Counters" },
//   { value: "711", label: "7-Eleven" },
//   { value: "INPY", label: "Instapay" },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BookingLayout() {
  const dispatch = useDispatch();
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const brandDetails = useSelector(
    (state) => state?.voucherBrandReducer?.voucherBrandInfo
  );
  const isVoucherBrandLoading = useSelector(
    (state) => state?.voucherBrandReducer?.isBrandLoading
  );
  const voucherDetails = useSelector((state) => state?.voucherReducer?.voucher);
  const isVoucherLoading = useSelector(
    (state) => state?.voucherReducer?.isVoucherLoading
  );
  const { ID, voucherUID } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsBookingLoading(true);
    const res = await dispatch(BOOK_VOUCHER(voucherUID, data));
    const paymentUrl = res?.data?.payment_url;
    if (res?.data?.payment_url) {
      window.open(paymentUrl, "_blank");
    } else {
      const options = {
        key: process.env.REACT_APP_RAZOR_KEY_ID,
        amount: res.data.amount,
        currency: "INR",
        name: "AmpCreds",
        description: "Test Transaction",
        image: "/amp-creds-logo.png",
        order_id: res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_BASE_URL}/webhook/razorpay`,
        theme: {
          color: "#4F46E5",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      // navigate(`/booking/${res?.data.booking.uid}`);
    }
  };

  useEffect(() => {
    dispatch(GET_VOUCHERS_BRAND_BY_ID(ID));
    dispatch(GET_VOUCHERS_BY_ID(voucherUID));
  }, [dispatch, ID, voucherUID]);

  return isVoucherBrandLoading || isVoucherLoading || isBookingLoading ? (
    <ScreenLoaderComponent />
  ) : (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Checkout</h2>
        <form
          className="lg:grid lg:grid-cols-1 lg:gap-x-20 xl:gap-x-28"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Order summary */}
          <div className="mt-10 lg:mt-0">
            <h2 className="text-lg font-medium text-gray-900">Order summary</h2>

            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <h3 className="sr-only">Items in your cart</h3>
              <div role="list" className="divide-y divide-gray-200">
                <div className="flex px-4 py-6 sm:px-6">
                  <div className="flex-shrink-0">
                    <img
                      src={brandDetails?.logo}
                      alt={brandDetails?.logo}
                      className="w-20 rounded-md"
                    />
                  </div>
                  <div className="ml-6 flex flex-1 flex-col">
                    <div className="flex">
                      <div className="min-w-0 flex-1">
                        <h4 className="text-3xl text-gray-700">
                          {voucherDetails?.description}
                        </h4>
                      </div>
                    </div>

                    <div className="flex flex-1 items-end justify-between pt-2">
                      <p className="mt-1 text-sm font-medium text-gray-900">
                        {voucherDetails?.currency}{" "}
                        <span>{voucherDetails?.discounted_price}</span>{" "}
                        <span className="line-through">
                          {voucherDetails?.actual_price}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 border-t border-gray-200 p-2 pt-10">
                <h2 className="text-lg font-medium text-gray-900">Details</h2>
                <blockquote>{brandDetails?.description}</blockquote>
                <div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-4">
                    {voucherDetails?.type === "GAMES-TOP-UP"
                      ? brandDetails?.fields_config?.map((field) => (
                          <div
                            key={field.name}
                            className="mt-6 grid grid-cols-4 gap-x-4 gap-y-6"
                          >
                            <div className="col-span-4">
                              <label
                                htmlFor={field.name}
                                className="block text-sm font-medium text-gray-700"
                              >
                                {field?.label}
                              </label>
                              <div className="mt-1">
                                {field.name === "clientserverid" ? (
                                  <>
                                    {field?.values?.length ? (
                                      <select
                                        type="text"
                                        id={field.name}
                                        name={field.name}
                                        {...register(field.name, {
                                          required: true,
                                        })}
                                        className={classNames(
                                          errors.hasOwnProperty(field.name)
                                            ? "border rounded-lg border-rose-600 ring-rose-600"
                                            : "border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500",
                                          "w-full shadow-sm lg:text-base p-px"
                                        )}
                                      >
                                        <option value="">
                                          Select Server Id
                                        </option>
                                        {field?.values.map((option) => (
                                          <option
                                            key={option.data_value}
                                            value={option.data_value}
                                          >
                                            {option.data_name}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      <>
                                        <input
                                          type="text"
                                          id={field.name}
                                          name={field.name}
                                          placeholder={`Enter ${field.label}`}
                                          {...register(field.name, {
                                            required: true,
                                          })}
                                          className={classNames(
                                            errors.hasOwnProperty(field.name)
                                              ? "border rounded-lg border-rose-600 ring-rose-600"
                                              : "border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500",
                                            "w-full shadow-sm lg:text-base p-px"
                                          )}
                                        />
                                        {errors.hasOwnProperty(field.name) && (
                                          <span className="text-rose-600 text-sm">
                                            {field.label} is Required
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {errors.hasOwnProperty(field.name) && (
                                      <span className="text-rose-600 text-sm">
                                        {field.label} is Required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <input
                                      type="text"
                                      id={field.name}
                                      name={field.name}
                                      placeholder={`Enter ${field.label}`}
                                      {...register(field.name, {
                                        required: true,
                                      })}
                                      className={classNames(
                                        errors.hasOwnProperty(field.name)
                                          ? "border rounded-lg border-rose-600 ring-rose-600"
                                          : "border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500",
                                        "w-full shadow-sm lg:text-base p-px"
                                      )}
                                    />
                                    {errors.hasOwnProperty(field.name) && (
                                      <span className="text-rose-600 text-sm">
                                        {field.label} is Required
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      : inputsBasedOnTypes[voucherDetails?.type]?.map(
                          (input) => (
                            <Fragment key={input.id}>
                              <div className="mt-6 grid grid-cols-4 gap-x-4 gap-y-6">
                                <div className="col-span-4">
                                  <label
                                    htmlFor={input.id}
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    {input.label}
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type={input.type}
                                      id={input.id}
                                      name={input.name}
                                      {...register(input.name, {
                                        required: input.required,
                                        minLength: input.minLength,
                                        maxLength: input.maxLength,
                                      })}
                                      className={classNames(
                                        errors.hasOwnProperty(input.id)
                                          ? "border-2 rounded-lg border-rose-600 ring-rose-600"
                                          : "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500",
                                        "w-full shadow-sm lg:text-lg p-px"
                                      )}
                                    />
                                    {errors.hasOwnProperty(input.id) && (
                                      <span className="text-rose-600 text-sm">
                                        {input.errors[errors[input.id].type]}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          )
                        )}
                  </div>
                  {/* <div className="mt-2 text-base">
                    {brandDetails?.description}
                  </div> */}
                </div>
              </div>
              <div className="border-t-2 border-dashed space-y-6 mt-10 border-gray-400 px-4 sm:px-6">
                <div className="flex items-center justify-between border-gray-200 pt-6">
                  <dt className="text-base font-medium">Total</dt>
                  <dd className="text-base font-medium text-gray-900">
                    {voucherDetails?.currency}{" "}
                    <span>{voucherDetails?.discounted_price}</span>{" "}
                    <span className="line-through">
                      {voucherDetails?.actual_price}
                    </span>
                  </dd>
                </div>
              </div>

              <div className="px-4 py-6 sm:px-6">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm"
                >
                  Confirm order
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
